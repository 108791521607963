import React from 'react';
import styled from 'styled-components'
import justinPic from './assets/justin.png'

const AppContainer = styled.div`
	text-align: center;
`

const AppHeader = styled.div`
	color: ${props => props.theme.color};
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
  font-size: calc(12px + 2vmin);
  letter-spacing: 0.2em;
`

const Letter = styled.span`
  color: ${props => props.isLower ? props.theme.color : props.theme.linkColor};
  font-weight: ${props => props.isLower ? 'none' : 'bold'};
`

const Text = styled.div`
  position: relative;
  animation: ${props => props.fadein ? 'fadein 3s' : ''};

  @keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
  }

  @keyframes growline{
    from { width: 0%; }
    to { width: 100%; }
  }

   &:after {
    background: ${props => props.underline ? props.theme.linkColor : 'none'};
    animation: ${props => props.underline ? 'growline 3s' : ''};
    border-radius: 5px;
    position: absolute;
    content: '';
    bottom: -10px;
    left: 0.75em;
    height: 6px;
    width: 100%;
  }

  &:hover:after {
    width: 100%;
  }
`

const ButtonGroupStyles = styled.div`
  display: flex;
  animation: fadein 6s;
  justify-content: center;
  flex-wrap: wrap;
`

const Button = styled.div`
  font-size: calc(8px + 1vmin);
  letter-spacing: 1.5px;
  border: 2px solid ${props => props.theme.linkColor};
  padding: 0.5em 1em;
  cursor: pointer;
  position: relative;
  margin: 1em;
  background: linear-gradient(0deg, ${props => props.theme.background}, #272727);

  a {
      color: inherit;
      text-decoration: none;
    }

  &:hover {
    border-style: hidden;
    padding: calc(0.5em + 2px) calc(1em + 2px);
    color: #AA55FF;

    &:before,
    &:after {
      content: '';
      position: absolute;
      left: -2px;
      top: -2px;
      background: linear-gradient(
        45deg,
        ${props => props.theme.linkColor},
        #EEDDFF, #AA55FF, #662299,
        ${props => props.theme.linkColor},
        #EEDDFF, #AA55FF, #662299
      );
      background-size: 400%;
      width: calc(100% + 4px);
      height: calc(100% + 4px);
      z-index: -1;
      animation: steam 20s linear infinite;
    }

    &:after {
      filter: blur(10px);
    }
  }

  @keyframes steam {
    0% {
      background-position: 0 0;
    }
    50% {
      background-position: 400% 0;
    }
    100% {
      background-position: 0 0;
    }
  }
`

const ProfilePic = styled.img`
  width: 20vh;
  height: 20vh;
  margin: 2em;
  border-radius: 50%;
  box-shadow: 8px 8px 10px #662299;
  box-sizing: border-box;
  display: block;
  object-fit: cover;
  object-position: 0 30%;
  filter: grayscale(60%) opacity(80%) saturate(2);
`

const isLower = str => {
  return str !== str.toUpperCase()
}

const ColoredLine = props => {
  const letters = props.line.split('')

  return <Text fadein={props.fadein} underline={props.underline}>
    {letters.map(letter => {
      const lower = isLower(letter)
      return <Letter isLower={lower}>
        {letter}
      </Letter>
    })}
  </Text>
}

const Link = props => {
  return <Button>
    <a href={props.href} target="_blank" rel="noopener noreferrer">
      {props.children}
    </a>
    </Button>
}

const TitledGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 2em;
`

const Title = styled.h2`
  font-size: 1.1rem;
  color: ${props => props.theme.linkColor};
  text-align: left;
`

const ButtonGroup = props => {
  return <TitledGroup>
    <Title>{props.title}</Title>
    <ButtonGroupStyles>
      {props.children}
    </ButtonGroupStyles>
  </TitledGroup>
}

export const App = () => {
  return (
    <AppContainer>
			<AppHeader>
        <ProfilePic src={justinPic} />
        <ColoredLine line='needHelp?' />
        <ColoredLine underline fadein line='getJustin' />
        <ButtonGroup title="Links">
          <Link href="https://www.linkedin.com/in/the-justin-fisher">LinkedIn</Link>
          <Link href="https://github.com/thenerdfather86">GitHub</Link>
          <Link href="https://codepen.io/thenerdfather86">Codepen</Link>
        </ButtonGroup>
			</AppHeader>
		</AppContainer>
  )
}