import React from 'react'
import { ThemeProvider, createGlobalStyle } from 'styled-components'

const StoreContext = React.createContext([{}, () => {}])

const GlobalStyles = createGlobalStyle`
	html,
	body {
    height: 100%;
    width: 100%;
  }

  body {
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: ${props => props.theme.color};
    background-color: ${props => props.theme.background};
		font-family: ${props => props.theme.fontFamily};
		position: relative;
  }

  code {
    font-family: ${props => props.theme.codeFontFamily};
	}

	*,
	*::before,
	*::after {
  	box-sizing: border-box;
	}
`

const initialState = {
	theme: {
		background: '#282c34',
		color: '#fcfcfc',
		linkColor: '#AA55FF',
		fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif`,
		codeFontFamily: `source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;`
	}
}

export const globalStateReducer = (state, action) => {
	switch (action.type) {
		default:
			return state
	}
}

const StoreProvider = props => {
	const [state, dispatch] = React.useReducer(globalStateReducer, initialState)

	return (
		<StoreContext.Provider value={[state, dispatch]}>
			<ThemeProvider theme={state.theme}>
				<GlobalStyles />
				<>{props.children}</>
			</ThemeProvider>
		</StoreContext.Provider>
	)
}

const useStore = props => React.useContext(StoreContext)

export { StoreContext, StoreProvider, useStore }
